import React from "react"
import { Link } from "gatsby"
import LogoWhite from "../images/JustPax-Small-logo-white.svg"
import LogoGreen from "../images/JustPax-Logo.svg"
import ButtonPrimary from "../components/buttonprimary"

const ListLink = props => (
	<li>
		<Link to={props.to} activeClassName={props.activeClass}>{props.children}</Link>
	</li>
)

const ButtonInverse = props => (
	<Link to={props.to} className="button--inverse">{props.children}</Link>
)

export default function Layout({ children }) {
	return (
		<div>
			<header className="wrapper flex-container">
				<div class="header--logo">
					<Link to="/"><img src={LogoGreen} alt="JustPax Collective" className="header--logo-img" /></Link>
				</div>
				<div class="header--links">
					<ul>
						<ListLink to="/who-we-are" activeClass="activeLink">Who We Are</ListLink>
						<ListLink to="/services/development/" activeClass="activeLink">Development</ListLink>
						<ListLink to="/services/consulting/" activeClass="activeLink">Consulting</ListLink>
						<ListLink to="/services/learning-pods/" activeClass="activeLink">Learning Pods</ListLink>
						<ButtonPrimary buttonLink="/contact-us/" buttonText="Contact Us" activeClassName="activeLink"></ButtonPrimary>
					</ul>
				</div>
			</header>
			{children}
			<footer>
				<div class="wrapper">
					<div class="flex-container footer-links">
						<div className="footer--image">
							<img src={LogoWhite} alt="JustPax Collective Logo" style={{maxWidth:`375px`}} />
						</div>
						<div className="footer--link-list">
							<ul>
								<ListLink to="/who-we-are" activeClass="activeBottomLink">Who We Are</ListLink>
								<ListLink to="/services/development/" activeClass="activeBottomLink">Development</ListLink>
								<ListLink to="/services/consulting/" activeClass="activeBottomLink">Consulting</ListLink>
								<ListLink to="/services/learning-pods/" activeClass="activeBottomLink">Learning Pods</ListLink>
								<ButtonInverse to="/contact-us/">Contact us</ButtonInverse>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}
